@import '../../../Styles/SFColors.module.scss';

.body {
  padding: 24px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2px;
        display: inline-block;
        color: $sf-grey-600;
      }

      .value {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        word-wrap: break-word;
      }

      a.value {
        text-decoration: none;
        color: $sf-blue-500;
      }
    }
  }

  .incidentInfo {
    padding-top: 24px;

    .customLabel {
      font-size: 10px;
      line-height: normal;
      letter-spacing: 0.2px;
    }

    .value {
      text-wrap: pretty;
      overflow-wrap: anywhere;
    }

    hr {
      padding-bottom: 24px;
    }
  }
}

:global(.nightMode) {
  .body {
    ul {
      li {
        label {
          color: $sf-grey-400;
        }

        a.value {
          color: $sf-blue-200;
        }
      }
    }
  }
}

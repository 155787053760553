@import '../Styles/SFColors.module.scss';

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $sf-background-light;

  footer {
    padding-top: 24px;
    padding-bottom: 16px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $sf-grey-600;
  }
}

:global(.nightMode) {
  .app {
    background-color: $sf-background-dark;

    footer {
      color: $sf-grey-400;
    }
  }
}

// SFBlue palette
$sf-blue-50: #e5f6ff;
$sf-blue-100: #ccebff;
$sf-blue-200: #80c6ff;
$sf-blue-300: #5cadff;
$sf-blue-400: #338fff;
$sf-blue-500: #0066ff;
$sf-blue-600: #0050e6;
$sf-blue-700: #003dcc;
$sf-blue-800: #002699;
$sf-blue-900: #001466;

// SFRed palette
$sf-red-50: #fceeef;
$sf-red-100: #f9dcde;
$sf-red-200: #f0a8ad;
$sf-red-300: #eb8e95;
$sf-red-400: #e7747c;
$sf-red-500: #db343e;
$sf-red-600: #c7232e;
$sf-red-700: #ad1f29;
$sf-red-800: #821724;
$sf-red-900: #5f111e;

// SFGrey palette
$sf-grey-a100: #cccccc;
$sf-grey-a200: #b2b2b2;
$sf-grey-a400: #333333;
$sf-grey-a700: #666666;
$sf-grey-50: #f2f2f2;
$sf-grey-100: #e5e5e5;
$sf-grey-200: #cccccc;
$sf-grey-300: #b2b2b2;
$sf-grey-400: #999999;
$sf-grey-500: #808080;
$sf-grey-600: #666666;
$sf-grey-700: #4d4d4d;
$sf-grey-800: #333333;
$sf-grey-900: #1a1a1a;

$sf-blue-main-light: $sf-blue-500;
$sf-blue-main-dark: $sf-blue-200;

$sf-red-main-light: $sf-red-700;
$sf-red-main-dark: $sf-red-200;

$sf-grey-main-light: $sf-grey-600;
$sf-grey-main-dark: $sf-grey-400;

$sf-background-light: #fafafa;
$sf-background-dark: #121212;

$sf-surface-light: #ffffff;
$sf-surface-dark: #1f1f1f;

$sf-text-white: #f2f2f2;
$sf-text-black: #1a1a1a;

$sf-common-white: #ffffff;
$sf-common-black: #000000;

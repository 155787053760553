@import '../../Styles/SFColors.module.scss';

.card {
  margin-top: 16px;
  width: 288px;
  border-radius: 2px;
  background-color: $sf-surface-light;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

  hr {
    height: 0;
    border: 0;
    margin: 0;
    border-top: 1px solid $sf-grey-100;
  }
}

:global(.nightMode) {
  .card {
    background-color: $sf-surface-dark;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

    hr {
      border-top: 1px solid $sf-grey-700;
    }
  }
}

@media screen and (min-width: 375px) {
  .card {
    width: 343px;
  }
}

@import '../../../Styles/SFColors.module.scss';

.header {
  font-family: Roboto;
  padding: 36px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .badgeContainer {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin-bottom: 12px;
    background: $sf-surface-light;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      border-radius: 50%;
    }

    .noBadge {
      text-transform: uppercase;
      color: $sf-blue-400;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
    }
  }

  h2 {
    color: $sf-text-black;
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
  }

  h3 {
    color: $sf-grey-a700;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
  }
}

:global(.nightMode) {
  .header {
    h2 {
      color: $sf-text-white;
    }

    h3 {
      color: $sf-grey-400;
    }
  }
}

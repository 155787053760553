/* bring in normalize.css styles */
@import-normalize;
@import 'Styles/SFColors.module.scss';

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $sf-background-light;
  color: $sf-text-black;

  &.nightMode {
    background-color: $sf-background-dark;
    color: $sf-text-white;
  }
}

#root {
  height: 100vh;
}
